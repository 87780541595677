import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginatedResponse } from '../../models/pagination.model';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  public _totalPages: number;
  public _currentPage: number;
  public _selectedPageSize: number;

  public _paginatedData: PaginatedResponse;
  @Input() set paginatedData(value: PaginatedResponse) {
    this._paginatedData = value;
    this.initData();
    console.warn(this._paginatedData);
  }

  public pageSizes: Array<number> = [5, 10, 20, 30, 50];
  public pages: number[] = [];
  public canGoBack: boolean = false;
  public canGoForward: boolean = false;

  @Output() onChangeCurrentPage: EventEmitter<number> = new EventEmitter();
  @Output() onSelectPageSize: EventEmitter<number> = new EventEmitter();

  ngOnInit(): void {
  }

  private initData() {
    if(!this._paginatedData) return;
    this.canGoBack = this._paginatedData.total_pages > 0 && !this._paginatedData.first;
    this.canGoForward = this._paginatedData.total_pages > 0 && !this._paginatedData.last;
    this._currentPage = this._paginatedData.pageable.page_number + 1; // page_number is 0 indexed, this is the number in pagination so page_number + 1
    this.pages = this.range(this._currentPage === 1 ? 1 : this._currentPage - 1, this._currentPage === this._paginatedData.total_pages ? this._currentPage : this._currentPage + 1);
    this._selectedPageSize = this._paginatedData.pageable.page_size;
  }

  private range(start: number, end: number): number[] {
    let res: number[] = [];
    for(let i = start; i <= end; i++) {
      res.push(i);
    }
    return res;
  }

  public goFirstPage(): void {
    this.changePage(1);
  }

  public goLastPage(): void {
    this.changePage(this._paginatedData.total_pages);
  }

  public goPrevPage(): void {
    this.changePage(this._currentPage - 1);
  }

  public goToPage(page: number): void {
    this.changePage(page);
  }

  public goNextPage(): void {
    this.changePage(this._currentPage + 1);
  }

  public selectPageSize(size: number) {
    this.onSelectPageSize.emit(size);
  }

  private changePage(page: number): void {
    // emit a 0 indexed value
    this.onChangeCurrentPage.emit(page - 1);
  }
}