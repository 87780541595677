import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase, deleteDB, IDBPTransaction } from 'idb';
import { from, Observable } from 'rxjs';
import { AccountFacade } from '../../../account/store/facade';
import { Meeting } from '../../../video/models/video.models';
import { AddressbookRecord, ADDRESSBOOK_TYPES } from '../addressbook';
import { CallHistoryRecord, CallHistoryRecordType } from '../callhistory';
import {
  AddressbookIndexes,
  CallHistoryIndexes,
  DatabaseSchema,
  DATABASE_NAME,
  DATABASE_VERSION,
  DBIndexes,
  DBStores,
  VideoconferenceIndexes
} from './database.model';
import { DBMigrationMethods } from './db-migration-methods-collection';

/**
 * This is the database IndexedDB interface, using idb as converter from old callback style
 * to promises.
 * All internal work is done with promises and usage of aync/await functionality
 * of javascript, however all output from public methods are in observable.
 * The reason to use promises internally is to avoid converting all idb function to observable.
 * References links:
 * IndexedDB API: https://developer.mozilla.org/it/docs/Web/API/IndexedDB_API
 * Google tutorial (while outdated, concepts are still good): https://developers.google.com/web/ilt/pwa/working-with-indexeddb
 * Jake Archibald's promises library: https://github.com/jakearchibald/idb
 */


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private currentUsername: string;
  //TODO: Change all any with the correct types later when they are available


  constructor(
    private accountFacade: AccountFacade
  ) {
    accountFacade.account$.subscribe((res) => {
      if (!res) return;
      this.currentUsername = res.username;
    })
  }

  //------------ Database general interface -----------//

  /**
   * Open the database
   * @private
   * @returns {Promise<IDBPDatabase<DatabaseSchema>>} A promise that resolves to the database
   */
  private openDb(): Promise<IDBPDatabase<DatabaseSchema>> {
    return openDB(this.currentUsername, DATABASE_VERSION, {
      upgrade: this.upgradeCallback
    });
  }

  /**
   * Callback called when the openDB is called with a version >= of the current (or created)
   * Inside the schema of the database is defined.
   * @private
   * @param {IDBPDatabase<DatabaseSchema>} db Database
   */
  private upgradeCallback(db: IDBPDatabase<DatabaseSchema>, oldVersion: number, newVersion: number, transaction: any) {


    // remove externals from db with a tmp function.
    (async () => {
      for (let currentVersion = oldVersion; currentVersion < newVersion; currentVersion++) {
        DBMigrationMethods[currentVersion + 1]?.call(this, db, oldVersion, newVersion, transaction);
      }
    })()


    // TODO: Add correct indexes when the targets models are ready
    if (!db.objectStoreNames.contains(DBStores.ADDRESSBOOK_OS)) {
      const addressbookStore = db.createObjectStore(DBStores.ADDRESSBOOK_OS, { keyPath: 'id', autoIncrement: true });
      addressbookStore.createIndex(AddressbookIndexes.BY_REMOTE_ID, 'remoteId', { unique: true });
      addressbookStore.createIndex(AddressbookIndexes.BY_TYPE, 'type');
      addressbookStore.createIndex(AddressbookIndexes.BY_NUMBER, 'defaultNumber.number');
      addressbookStore.createIndex(AddressbookIndexes.BY_FIRSTNAME, 'firstName');
      addressbookStore.createIndex(AddressbookIndexes.BY_LASTNAME, 'lastName');
      addressbookStore.createIndex(AddressbookIndexes.BY_FAVORITE, 'favorite');
      addressbookStore.createIndex(AddressbookIndexes.BY_HASH, 'hash');
    }

    if (!db.objectStoreNames.contains(DBStores.CALLHISTORY_OS)) {
      const callHistoryStore = db.createObjectStore(DBStores.CALLHISTORY_OS, { keyPath: 'id', autoIncrement: true });
      callHistoryStore.createIndex(CallHistoryIndexes.BY_DATE, 'date');
      callHistoryStore.createIndex(CallHistoryIndexes.BY_NUMBER, 'displayName.number');
      callHistoryStore.createIndex(CallHistoryIndexes.BY_TYPE, 'type');
    }

    if (!db.objectStoreNames.contains(DBStores.VIDEOCONFERENCE_OS)) {
      const videoconferenceStore = db.createObjectStore(DBStores.VIDEOCONFERENCE_OS, { keyPath: 'id', autoIncrement: true });
      videoconferenceStore.createIndex(VideoconferenceIndexes.BY_DATE, 'date');
      videoconferenceStore.createIndex(VideoconferenceIndexes.BY_NAME, 'name');
      videoconferenceStore.createIndex(VideoconferenceIndexes.BY_TYPE, 'type');
    }
  }

  /**
   * Add a single data into the ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {} data Data to add to the ObjectStore
   * @returns {Promise<string>} Returns a string representing the primary key of the added data
   */
  private async add(storeName: DBStores, data: any): Promise<string> {
    const db = await this.openDb();
    const returnPromise = db.add(storeName, data)
    db.close();
    return returnPromise;
  }

  /**
   * Add multiple data into the ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore   
   * @param {} data Array containing data to save into the ObjectStore
   * @returns {Promise<string[]>} Returns an array of primary keys added
   */
  private async addMultiple(storeName: DBStores, data: any[]): Promise<string[]> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readwrite');
    let promises = [];
    data.forEach(el => {
      promises.push(
        tx.store.add(el)
      );
    });
    db.close();
    return Promise.all<string>(promises);
  }

  /**
   * Update or add multiple data into the ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore   
   * @param {} data Array containing data to save into the ObjectStore
   * @returns {Promise<string[]>} Returns an array of primary keys added
   */
  private async addOrUpdateMultiple(storeName: DBStores, data: any[]): Promise<string[]> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readwrite');
    let promises = [];
    data.forEach(el => {
      promises.push(
        tx.store.put(el)
      );
    });
    db.close();
    return Promise.all<string>(promises);
  }

  /**
   * Get a value from the ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore   
   * @param {string} key Primary key value used to get the record
   * @returns {} Returns a promise that resolve into the value if found, undefined otherwise
   */
  private async get(storeName: DBStores, key: string): Promise<any> {
    const db = await this.openDb();
    const result = db.get(storeName, key);
    db.close();
    return result;
  }

  /**
   * Get all the records from an ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @returns {} Returns a promise that resolve into the array with the values found, undefined otherwise
   */
  private async getAll(storeName: DBStores): Promise<any[]> {
    const db = await this.openDb();
    const result = db.getAll(storeName);
    db.close();
    return result;
  }

  /**
   * Get all the records from an ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @returns {} Returns a promise that resolve into the array with the values found, undefined otherwise
   */
  private async getAllReversed(storeName: DBStores): Promise<any[]> {
    const db = await this.openDb();
    const tx = await db.transaction(storeName, 'readonly');
    let cursor = await tx.store.openCursor(undefined, 'prev');
    let promises = [];
    while (cursor) {
      promises.push(cursor.value);
      cursor = await cursor.continue();
    }
    db.close();
    return promises;
  }

  /**
   * Get some records from the ObjectStore
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @returns {} Returns a promise that resolve into the array with the values found, undefined otherwise
   */
  private async getSome(storeName: DBStores, start: any, n: number, exclude: boolean = false): Promise<any[]> {
    const db = await this.openDb();
    const tx = await db.transaction(storeName, 'readonly');
    let cursor = await tx.store.openCursor(IDBKeyRange.lowerBound(start, exclude));
    let promises = [];
    for (let i = 0; i < n; i++) {
      if (!cursor) break;
      promises.push(cursor.value);
      cursor = await cursor.continue();
    }
    db.close();
    return promises;
  }

  /**
   * Get a value from the ObjectStore with the selected index
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {DBIndexes} index Name of the index to use
   * @param {string} key Primary key value used to get the record
   * @returns {} Returns a promise that resolve into the value if found, undefined otherwise
   */
  private async getFromIndex(storeName: DBStores, index: DBIndexes, key: any): Promise<any> {
    const db = await this.openDb();
    const result = db.getFromIndex(storeName, index, key);
    db.close();
    return result;
  }

  /**
   * Implementation of contains for strings
   * IMPORTANT: Works only on strings
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {DBIndexes} index Name of the index to use
   * @param {string} searchValue Primary key value used to get the record
   */
  private async getFromIndexContains(storeName: DBStores, index: DBIndexes, searchValue: string, caseInsensitive: boolean = false): Promise<any[]> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readonly');
    const idx = tx.store.index(index);
    let cursor = await idx.openCursor();
    let results = [];
    while (cursor) {
      let v = caseInsensitive ? (<string>cursor.key).toLowerCase() : (<string>cursor.key);
      if (v.includes(searchValue)) results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  /**
   * Implementation of starts with for strings
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {DBIndexes} index Name of the index to use
   * @param {string} searchValue Primary key value used to get the record
   */
  private async getFromIndexStartsWith(storeName: DBStores, index: DBIndexes, searchValue: string): Promise<any[]> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readonly');
    const idx = tx.store.index(index);
    let cursor = await idx.openCursor(IDBKeyRange.bound(searchValue, searchValue + '\uffff', false, false));
    let results = [];
    while (cursor) {
      results.push(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  /**
   * 
   * @param {DBStores} storeName Name of the ObjectStore 
   * @param {DBIndexes} index Name of the index to use 
   * @param {any} filter The filter to apply. Must be exact
   * @returns {Promise<any[]>} Returns the records found
   */
  private async getAllFromIndexByFilter(storeName: DBStores, index: DBIndexes, filter: any): Promise<any[]> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readonly');
    const idx = tx.store.index(index);
    let cursor = await idx.openCursor(filter);
    let results = [];
    while (cursor) {
      if (cursor.key === filter) results.push(cursor.value);
      cursor = await cursor.continue();
    };
    return results;
  }

  /**
   * Get some records from the ObjectStore with the selected index
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {DBIndexes} index Name of the index to use
   * @param {any} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   */
  private async getSomeFromIndex(storeName: DBStores, index: DBIndexes, start: any = undefined, n: number, exclude: boolean = false, reverse: boolean = false) {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readonly');
    const idx = tx.store.index(index);
    let cursor = start ? await idx.openCursor(IDBKeyRange.lowerBound(start, exclude), reverse ? 'prev' : 'next') : await idx.openCursor(null, reverse ? 'prev' : 'next');
    let promises = [];
    if (!cursor) {
      db.close();
      return promises;
    }
    for (let i = 0; i < n; i++) {
      promises.push(cursor.value);
      cursor = await cursor.continue();
      if (!cursor) break;
    }
    db.close();
    return promises;
  }

  /**
   * Get some records from the ObjectStore with the selected index
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {DBIndexes} index Name of the index to use
   * @param {any} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {any[]} filters The filter to apply. Must be exact
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   */
  private async getSomeFromIndexByFiltersAndKey(storeName: DBStores, index: DBIndexes, start: any = undefined, n: number, filters: any[], key: string, exclude: boolean = false, reverse: boolean = false) {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readonly');
    const idx = tx.store.index(index);
    let cursor = start ? await idx.openCursor(IDBKeyRange.lowerBound(start, exclude), reverse ? 'prev' : 'next') : await idx.openCursor(null, reverse ? 'prev' : 'next');
    let promises = [];
    let count = 0;
    if (!cursor) {
      db.close();
      return promises;
    }
    while (count < n) {
      if (filters.some(f => f === cursor.value[key])) {
        promises.push(cursor.value);
        count++;
      }
      cursor = await cursor.continue();
      if (!cursor) break;
    }
    db.close();
    return promises;
  }

  /**
   * Update a record the ObjectStore
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {} newData New value to replace the old
   * @returns {} A Promise resolving to the new key of the data
   */
  private async update(storeName: DBStores, newData: any): Promise<string> {
    const db = await this.openDb();
    const result = db.put(storeName, newData);
    db.close();
    return result;
  }

  /**
   * Delete a record from the ObjectStore found by the key
   * @private
   * @param {DBStores} storeName Name of the ObjectStore
   * @param {string} key Primary key value used to get the record
   * @returns {Promise<void>} Returns a promise resolving when the delete is done 
   */
  private async delete(storeName: DBStores, key: any): Promise<void> {
    const db = await this.openDb();
    const result = db.delete(storeName, key);
    db.close();
    return result;
  }

  private async deleteAllContactsFromIndex(storeName: DBStores, index: DBIndexes, filter: any): Promise<number> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readwrite');
    const idx = tx.store.index(index);
    let cursor = await idx.openCursor(filter);
    let count = 0;
    while (cursor) {
      if (cursor.key === filter) {
        await tx.store.delete(cursor.primaryKey);
        count++;
      }
      cursor = await cursor.continue();
    }
    db.close();
    return 0;
  }

  /**
   * Clear an object store. Basically delete of records.
   * @param {DBStores} storeName Name of the object store to clear
   * @returns {Promise<void>} Returns a promise resolving when the delete is done 
   */
  private async clearObjectStore(storeName: DBStores): Promise<void> {
    const db = await this.openDb();
    const tx = db.transaction(storeName, 'readwrite');
    return await tx.store.clear();
  }

  /**
   * Delete the whole database
   * @returns {Observable<void>} An Observable without any value.
   */
  public deleteDb(): Observable<void> {
    return from(deleteDB(DATABASE_NAME));
  }

  //------------ Addressbook record specifics interface -----------//

  /**
   * Add a new addressbook record to the database
   * @param { AddressbookRecord } data Data to add into the addressbook database
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public addAddressbookRecord(data: AddressbookRecord): Observable<string> {
    return from(this.add(DBStores.ADDRESSBOOK_OS, data));
  }

  /**
   * Add multiple addressbook records to the database
   * @param {AddressbookRecord} data Data to add into the addressbook database
   * @returns {Observable<string[]>} An observable resolving to an array of strings with the primary keys used
   */
  public addMultipleAddressbookRecords(data: AddressbookRecord[]): Observable<string[]> {
    return from(this.addMultiple(DBStores.ADDRESSBOOK_OS, data));
  }

  /**
   * Get a single addressbook record from the database
   * @param {string} key Key to use to get the data from the addressbook database
   * @returns {Observable<AddressbookRecord>} An observable resolving to the value of the record searched or undefined if not found
   */
  public getAddressbookRecord(key: string): Observable<AddressbookRecord> {
    return from(this.get(DBStores.ADDRESSBOOK_OS, key));
  }

  /**
   * Get all the addressbook records from the database
   * @returns {Observable<AddressbookRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getAllAddressbookRecords(): Observable<AddressbookRecord[]> {
    return from(this.getAll(DBStores.ADDRESSBOOK_OS));
  }

  /**
   * Get a number of addressbook records from a point in the database
   * @param {string} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @returns {Observable<AddressbookRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeAddressbookRecords(start: string, n: number, exclude = false): Observable<AddressbookRecord[]> {
    return from(this.getSome(DBStores.ADDRESSBOOK_OS, start, n, exclude));
  }

  /**
   * Get the addressbook record by id used on backend
   * @param {string} id Id to search
   */
  public getAddressbookByRemoteId(id: string): Observable<AddressbookRecord> {
    return from(this.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_REMOTE_ID, id));
  }

  /**
   * Get all the addressbook records filtering by type
   * @param {ADDRESSBOOK_TYPES} type Type of the addressbook
   * @returns {Observable<AddressbookRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getAllAddressbookByType(type: ADDRESSBOOK_TYPES): Observable<AddressbookRecord[]> {
    return from(this.getAllFromIndexByFilter(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_TYPE, type));
  };

  /**
   * Get the addressbook record by number
   * @param {string} number Number to search
   */
  public getAddressbookByNumber(number: string): Observable<AddressbookRecord> {
    return from(this.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_NUMBER, number));
  };

  /**
   * Get the addressbook record by first name
   * @param {string} firstName First name to search
   */
  public getAddressbookByFirstName(firstName: string): Observable<AddressbookRecord> {
    return from(this.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_FIRSTNAME, firstName));
  };

  /**
   * Get the addressbook record by last name
   * @param {string} lastName Last name to search
   */
  public getAddressbookByLastName(lastName: string): Observable<AddressbookRecord> {
    return from(this.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_LASTNAME, lastName));
  };

  /**
   * Get the addressbook record by hash
   * @param {string} number Number to search
   */
  public getAddressbookByHash(hash: string): Observable<AddressbookRecord> {
    return from(this.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_HASH, hash));
  };

  public getAddressbookByFavorite(favorite: number = 1) {
    return from(this.getAllFromIndexByFilter(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_FAVORITE, favorite));
  }

  /**
   * Update a single addressbook record
   * @param {AddressbookRecord} data The new data to update
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public updateAddressbookRecord(data: AddressbookRecord): Observable<string> {
    return from(this.update(DBStores.ADDRESSBOOK_OS, data));
  }

  /**
   * Update multiple addressbook records
   * @param {AddressbookRecord} data The new data to update
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public addOrUpdateAddressbookRecord(data: AddressbookRecord[]): Observable<string[]> {
    return from(this.addOrUpdateMultiple(DBStores.ADDRESSBOOK_OS, data));
  }

  /**
   * Delete a single addressbook record
   * @param key Key to use to delete the data from the addressbook database
   */
  public deleteAddressbookRecord(key: any): Observable<void> {
    return from(this.delete(DBStores.ADDRESSBOOK_OS, key));
  }

  /**
   * Clear the addressbook objectstore
   */
  public clearAddressbookStore(): Observable<void> {
    return from(this.clearObjectStore(DBStores.ADDRESSBOOK_OS));
  }

  //------------ Performant contacts search by number -----------//

  // TODO: vedi bene il DBSTore

  private async getFirstContactByNumber(number: string) {
    const db = await this.openDb();
    const internalRes = await db.getFromIndex(DBStores.ADDRESSBOOK_OS, AddressbookIndexes.BY_NUMBER, <any>number);
    if (internalRes) {
      db.close();
      return internalRes;
    }
    db.close();
    return null;
  }

  public getFirstContactFromInternal(number: string) {
    return from(this.getFirstContactByNumber(number));
  }

  //------------ Call History specifics interface -----------//

  /**
   * Add a new call history record to the database
   * @param {CallHistoryRecord} data Data to add into the addressbook database
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public addCallHistoryRecord(data: CallHistoryRecord): Observable<string> {
    return from(this.add(DBStores.CALLHISTORY_OS, data));
  }

  /**
   * Add multiple call history records to the database
   * @param {CallHistoryRecord} data Data to add into the addressbook database
   * @returns {Observable<string[]>} An observable resolving to an array of strings with the primary keys used
   */
  public addMultipleCallHistoryRecords(data: CallHistoryRecord[]): Observable<string[]> {
    return from(this.addMultiple(DBStores.CALLHISTORY_OS, data));
  }

  /**
   * Get a single call history record from the database
   * @param key Key to use to get the data from the addressbook database
   * @returns {Observable<CallHistoryRecord>} An observable resolving to the value of the record searched or undefined if not found
   */
  public getCallHistoryRecord(key: string): Observable<CallHistoryRecord> {
    return from(this.get(DBStores.CALLHISTORY_OS, key));
  }

  /**
   * Get all the call history records from the database
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getAllCallHistoryRecords(): Observable<CallHistoryRecord[]> {
    return from(this.getAll(DBStores.CALLHISTORY_OS));
  }

  /**
   * Get all the call history records from the database
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getAllCallHistoryRecordsReversed(): Observable<CallHistoryRecord[]> {
    return from(this.getAllReversed(DBStores.CALLHISTORY_OS));
  }

  /**
   * Get a number of call history records from a point in the database
   * @param {string} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeCallHistoryRecords(start: number, n: number, exclusive: boolean = false): Observable<CallHistoryRecord[]> {
    return from(this.getSome(DBStores.CALLHISTORY_OS, start, n, exclusive));
  }

  // TODO: As indexes will be defined, add specific method to use them
  /**
   * Get the call history record by exact date
   * @param {date} date The date to search for. Exact date
   * @returns {Observable<CallHistoryRecord>} An observable resolving to the records searched or undefined if not found
   */
  public getCallHistoryRecordByDate(date: Date): Observable<CallHistoryRecord> {
    return from(this.getFromIndex(DBStores.CALLHISTORY_OS, CallHistoryIndexes.BY_DATE, date));
  }

  /**
   * Get some call history records from the first (last if reverse = true) or from start (exact) date
   * @param {Date} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeCallHistoryRecordsByDate(n: number, start: Date = null, exclude: boolean = false, reverse: boolean = false): Observable<CallHistoryRecord[]> {
    return from(this.getSomeFromIndex(DBStores.CALLHISTORY_OS, CallHistoryIndexes.BY_DATE, start, n, exclude, reverse));
  }

  /**
   * Get the call history record by exact phone number
   * @param {string} number The phone number to search for. Exact phone number
   * @returns {Observable<CallHistoryRecord>} An observable resolving to the records searched or undefined if not found
   */
  public getCallHistoryRecordByNumber(number: string): Observable<CallHistoryRecord> {
    return from(this.getFromIndex(DBStores.CALLHISTORY_OS, CallHistoryIndexes.BY_NUMBER, number));
  }

  /**
   * Get some call history records from the first (last if reverse = true) or from start (exact) number
   * @param {string} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeCallHistoryRecordsByNumber(n: number, start: string = undefined, exclude: boolean = false, reverse: boolean = false): Observable<CallHistoryRecord[]> {
    return from(this.getSomeFromIndex(DBStores.CALLHISTORY_OS, CallHistoryIndexes.BY_NUMBER, start, n, exclude, reverse));
  }

  /**
   * Get some call history records from the first (last if reverse = true) or from start (exact) number
   * @param {string} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<CallHistoryRecord[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeCallHistoryRecordsByDateWithTypes(n: number, filters: CallHistoryRecordType[], start: string = undefined, exclude: boolean = false, reverse: boolean = false): Observable<CallHistoryRecord[]> {
    return from(this.getSomeFromIndexByFiltersAndKey(DBStores.CALLHISTORY_OS, CallHistoryIndexes.BY_DATE, start, n, filters, 'type', exclude, reverse));
  }

  /**
   * Update a single call history record
   * @param {CallHistoryRecord} data The new data to update
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public updateCallHistoryRecord(data: CallHistoryRecord): Observable<string> {
    return from(this.update(DBStores.CALLHISTORY_OS, data));
  }

  /**
   * Delete a single call history record
   * @param key Key to use to delete the data from the addressbook database
   */
  public deleteCallHistoryRecord(key: any): Observable<void> {
    return from(this.delete(DBStores.CALLHISTORY_OS, key));
  }

  /**
 * Clear call history records database
 */
  public clearCallHistoryRecords(): Observable<void> {
    return from(this.clearObjectStore(DBStores.CALLHISTORY_OS));
  }

  //------------ Videoconference specifics interface -----------//

  /**
   * Add a new meeting record to the database
   * @param {Meeting} data Data to add into the videoconference database
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public addMeetingRecord(data: Meeting): Observable<string> {
    return from(this.add(DBStores.VIDEOCONFERENCE_OS, data));
  }

  /**
   * Add multiple meeting records to the database
   * @param {Meeting} data Data to add into the videoconference database
   * @returns {Observable<string[]>} An observable resolving to an array of strings with the primary keys used
   */
  public addMultipleMeetingRecords(data: Meeting[]): Observable<string[]> {
    return from(this.addMultiple(DBStores.VIDEOCONFERENCE_OS, data));
  }

  /**
   * Get a single meeting record from the database
   * @param key Key to use to get the data from the videoconference database
   * @returns {Observable<Meeting>} An observable resolving to the value of the record searched or undefined if not found
   */
  public getMeetingRecord(key: string): Observable<Meeting> {
    return from(this.get(DBStores.VIDEOCONFERENCE_OS, key));
  }

  /**
   * Get all the meeting records from the database
   * @returns {Observable<Meeting[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getAllMeetingRecords(): Observable<Meeting[]> {
    return from(this.getAll(DBStores.VIDEOCONFERENCE_OS));
  }

  /**
   * Get a number of meeting records from a point in the database
   * @param {string} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @returns {Observable<Meeting[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeMeetingRecords(start: number, n: number, exclusive: boolean = false): Observable<Meeting[]> {
    return from(this.getSome(DBStores.VIDEOCONFERENCE_OS, start, n, exclusive));
  }

  // TODO: As indexes will be defined, add specific method to use them
  /**
   * Get the meeting records by exact date
   * @param {date} date The date to search for. Exact date
   * @returns {Observable<Meeting>} An observable resolving to the records searched or undefined if not found
   */
  public getMeetingByDate(date: Date): Observable<Meeting> {
    return from(this.getFromIndex(DBStores.VIDEOCONFERENCE_OS, VideoconferenceIndexes.BY_DATE, date));
  }

  /**
   * Get some meeting records from the first (last if reverse = true) or from start (exact) date
   * @param {Date} start Key from which start getting values
   * @param {number} n Number of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<Meeting[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeMeetingRecordsByDate(n: number, start: Date = null, exclude: boolean = false, reverse: boolean = false): Observable<Meeting[]> {
    return from(this.getSomeFromIndex(DBStores.CALLHISTORY_OS, VideoconferenceIndexes.BY_DATE, start, n, exclude, reverse));
  }

  /**
   * Get the meeting record by exact name
   * @param {string} name The name to search for. Exact name
   * @returns {Observable<Meeting>} An observable resolving to the records searched or undefined if not found
   */
  public getMeetingRecordByName(name: string): Observable<Meeting> {
    return from(this.getFromIndex(DBStores.VIDEOCONFERENCE_OS, VideoconferenceIndexes.BY_NAME, name));
  }

  /**
   * Get some meeting records from the first (last if reverse = true) or from start (exact) number
   * @param {string} start Key from which start getting values
   * @param {number} n Name of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<Meeting[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeMeetingRecordsByName(n: number, start: string = undefined, exclude: boolean = false, reverse: boolean = false): Observable<Meeting[]> {
    return from(this.getSomeFromIndex(DBStores.VIDEOCONFERENCE_OS, VideoconferenceIndexes.BY_NAME, start, n, exclude, reverse));
  }

  /**
 * Get the meeting record by exact type
 * @param {string} type The type to search for. Exact type
 * @returns {Observable<Meeting>} An observable resolving to the records searched or undefined if not found
 */
  public getMeetingRecordByType(type: string): Observable<Meeting> {
    return from(this.getFromIndex(DBStores.VIDEOCONFERENCE_OS, VideoconferenceIndexes.BY_TYPE, type));
  }

  /**
   * Get some meeting records from the first (last if reverse = true) or from start (exact) number
   * @param {string} start Key from which start getting values
   * @param {number} n Name of records to get 
   * @param {boolean} exclude Flag to include or not the start value
   * @param {boolean} reverse Flag to get data in the opposite direction
   * @returns {Observable<Meeting[]>} An observable resolving to an array values for the records searched or undefined if not found
   */
  public getSomeMeetingRecordsByType(n: number, start: string = undefined, exclude: boolean = false, reverse: boolean = false): Observable<Meeting[]> {
    return from(this.getSomeFromIndex(DBStores.VIDEOCONFERENCE_OS, VideoconferenceIndexes.BY_TYPE, start, n, exclude, reverse));
  }

  /**
   * Update a single meeting record
   * @param {Meeting} data The new data to update
   * @returns {Observable<string>} An observable resolving to the string with the primary key used
   */
  public updateMeetingRecord(data: Meeting): Observable<string> {
    return from(this.update(DBStores.VIDEOCONFERENCE_OS, data));
  }

  /**
   * Delete a single meeting record
   * @param key Key to use to delete the data from the videoconference database
   */
  public deleteMeetingRecord(key: any): Observable<void> {
    return from(this.delete(DBStores.VIDEOCONFERENCE_OS, key));
  }

  /**
 * Clear meeting records database
 */
  public clearMeetingRecords(): Observable<void> {
    return from(this.clearObjectStore(DBStores.VIDEOCONFERENCE_OS));
  }
}

