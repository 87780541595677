import { IDBPDatabase } from "idb";
import { AddressbookIndexes, DBStores, DatabaseSchema } from "./database.model";

export const DBMigrationMethods = [
  // from -1 to 0
  null,
  // from 0 to 1 
  null,
  // from 1 to 2 (index 2)
  async (db: IDBPDatabase<DatabaseSchema>, oldVersion: number, newVersion: number, transaction: any) => {
    if (transaction && db.objectStoreNames.contains(DBStores.ADDRESSBOOK_OS)) {
      const objstore = transaction.objectStore(DBStores.ADDRESSBOOK_OS);
      const idx = objstore.index(AddressbookIndexes.BY_TYPE);
      let cursor = await idx.openCursor()
      while (cursor) {
        if (cursor.key == 'external') {
          objstore.delete(cursor.primaryKey);
        }
        cursor = await cursor.continue();
      };
    }
  }
]
