import { Invitation, SessionDelegate, Subscriber } from 'sip.js';
import {SimpleSessionCallOptions } from './session.wrapper.model';

export interface NewSessionOptions {
  earlyMedia?: boolean;
  constraints?: MediaTrackConstraints;
  delegates?: SessionDelegate;
}

export interface SimpleCallUAOptions {
  newSessionOptions?: NewSessionOptions;
  simpleSessionCallOptions?: SimpleSessionCallOptions;
}

export interface UserAgentWrapperOptions {
  onInviteDelegate?: (invitation: Invitation) => void;
  onConnectDelegate?: () => void;
  onDisconnectDelegate?: () => void;
  onRegistered?: () => void;
  displayName?: string;
  kazooVersion?: 3 | 4;
}

export interface SubscriptionModel {
  subscription: Subscriber;
  target: string;
  event: SubscriptionEventTypes
}

export enum SubscriptionEventTypes {
  PRESENCE = 'presence'
}


