import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { SessionManagerService } from "../sip/sessions-manager.service";
import { AddressbookRecord, AddressbookService, ADDRESSBOOK_TYPES, CrmLookupResponse } from "../addressbook";
import { Call, CallModel } from "./call.model";
import { SessionContainer, SESSION_TYPE, SipService } from "../sip";
import { CallHistoryRecord } from "../callhistory";
import { Router } from "@angular/router";
import { IncomingCallService } from "./incoming-call.service";
import { EndCallOptions } from "../../sipjs/models";
import { ConfigService, DatabaseService, ElectronService } from "..";
import { ExternalPopupOpeningModes, IPC_CHANNELS } from "../../../../../electron-utils/electron.model";
import { Logger, LoggerService } from "../logger";
import { forkJoin, iif, Observable, of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { NavigationService, UtilsService } from "../../../shared/services";

@Injectable({
  providedIn: 'root'
})
export class CallService {

  private _calls: Call[] = [];
  private _activeCall: Call; // currently in progress
  private nextId: number = 0;
  private logger: Logger;

  public activeCallChange: EventEmitter<Call> = new EventEmitter();
  public callsChange: EventEmitter<Call[]> = new EventEmitter();

  constructor(
    private loggerService: LoggerService,
    private sessionManager: SessionManagerService,
    private sipService: SipService,
    private incomingCallService: IncomingCallService,
    private dbService: DatabaseService,
    private router: Router,
    private electronService: ElectronService,
    private addressbookService: AddressbookService,
    private navigationService: NavigationService,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {
    window['callService'] = this;
    this.logger = this.loggerService.getLoggerInstance('Call Service');
    this.sessionManager.callClosed.subscribe((sessionId: string) => {
      this.callClosed(sessionId);
    });
    this.sessionManager.newIncomingCall.subscribe((sessionContainer: SessionContainer) => {
      this.callFromIncoming(sessionContainer);
    });
    if (this.electronService.isElectron) {
      this.attachToRemoteActions();
    }
  }

  public get allCalls() {
    return this._calls;
  }

  public get activeCall() {
    return this._activeCall;
  }

  /**
   * It will switch to the call with id equals to callId.
   * It will also set pause to the current call before switching
   * @param {number} callId Id of the call to switch to
   * @returns {void}
   */
  public switchCall(callId: number): void {
    const call = this._calls.find(c => c.id === callId);
    if (!call) {
      this.logger.warn(`No call with id ${callId}`);
      return;
    }
    this._activeCall = call;
    this.activeCallChange.emit(this._activeCall);
  }

  /**
   * Add a call to the list of all calls. In case incoming is set to true, it will not pause other calls nor set this one as active
   * @param {Call} call Call to add to the all calls list
   * @param {boolean} incoming Flag to set if the call to add is incoming
   */
  public addCall(call: Call, incoming: boolean = false) {
    this._calls.push(call);
    this.callsChange.emit(this._calls);
    if (!incoming) {
      this._calls.forEach(c => {
        if (c.sessionContainer.type === SESSION_TYPE.ANSWERED)
          c.setPause();
      });
      this._activeCall = call;
      this.activeCallChange.emit(this._activeCall);
    }
  }

  /**
   * Create a call object from the incoming call and add it to the list of calls
   * @param sessionContainer Session container to associate to the call
   */
  public callFromIncoming(sessionContainer: SessionContainer) {
    const callOptions: CallModel = {
      id: this.nextId++,
      fullname: sessionContainer.session.remoteDisplayName.name.remote,
      number: sessionContainer.session.remoteDisplayName.number,
      original: null,
      session: sessionContainer
    };
    const call = new Call(callOptions);
    const s = call.sessionContainer.onCallHistoryCreated.pipe(
      filter((isCreated: boolean) => isCreated)
    ).subscribe(() => {
      this.searchContactInDbAndCrm(sessionContainer.session.remoteDisplayName.number).subscribe((contact: AddressbookRecord | CrmLookupResponse[]) => {
        if (contact['type']) call.updateCallWithDBContact(<AddressbookRecord>contact);
        else if ((<CrmLookupResponse[]>contact).length > 0) {
          call.updateCallWithCRMContact(<CrmLookupResponse>contact[0]);
        }
      });
      s.unsubscribe();
    })
    this.addCall(call);
    const sub = call.sessionContainer.changeTypeListener.subscribe((type) => {
      if (type === SESSION_TYPE.ANSWERED) {
        this.callsChange.emit(this._calls);
        sub.unsubscribe();
      } else if (type === SESSION_TYPE.TERMINATED) {
        sub.unsubscribe();
      }
    })
    this.incomingCallService.notifyIncomingCall(call);
    if(this.configService.config.settings?.externalPopup?.openingMode === ExternalPopupOpeningModes.open_on_ring) {
      this.utilService.openExternalLink(this.utilService.replaceExternalPopupKeys(
        this.configService.config.settings.externalPopup.externalLink,
        call.number
      ));
    }
  }

  public callFromRecord(record: AddressbookRecord, number: string): void {
    const callOptions: CallModel = {
      id: this.nextId++,
      fullname: `${record.firstName} ${record.lastName}`,
      number: number,
      original: record,
      session: this.sipService.call(number)
    }
    const call = new Call(callOptions);
    const s = call.sessionContainer.onCallHistoryCreated.pipe(
      filter((isCreated: boolean) => isCreated)
    ).subscribe(() => {
      call.updateHistoryRecord();
      s.unsubscribe();
    });
    this.addCall(call);
    this.goToCallView();
  }

  public callFromEvent(event: CallHistoryRecord) {
    const callOptions: CallModel = {
      id: this.nextId++,
      fullname: event.displayName.name.remote,
      number: event.displayName.number,
      original: event,
      session: this.sipService.call(event.displayName.number)
    }
    const call = new Call(callOptions);
    const s = call.sessionContainer.onCallHistoryCreated.pipe(
      filter((isCreated: boolean) => isCreated)
    ).subscribe(() => {
      this.searchContactInDbAndCrm(event.displayName.number).subscribe((contact: AddressbookRecord | CrmLookupResponse[]) => {
        if (contact['type']) call.updateCallWithDBContact(<AddressbookRecord>contact);
        else if ((<CrmLookupResponse[]>contact).length > 0) {
          call.updateCallWithCRMContact(<CrmLookupResponse>contact[0]);
        }
      });
      s.unsubscribe();
    })
    this.addCall(call);
    this.goToCallView();
  }

  public callFromNumber(number: string) {
    const callOptions: CallModel = {
      id: this.nextId++,
      fullname: number,
      number: number,
      original: null,
      session: this.sipService.call(number)
    }
    const call = new Call(callOptions);
    const s = call.sessionContainer.onCallHistoryCreated.pipe(
      filter((isCreated: boolean) => isCreated)
    ).subscribe(() => {
      this.searchContactInDbAndCrm(number).subscribe((contact: AddressbookRecord | CrmLookupResponse[]) => {
        if (contact['type']) call.updateCallWithDBContact(<AddressbookRecord>contact);
        else if ((<CrmLookupResponse[]>contact).length > 0) {
          call.updateCallWithCRMContact(<CrmLookupResponse>contact[0]);
        }
      });
      s.unsubscribe();
    })
    this.addCall(call);
    this.goToCallView();
  }

  public acceptCall(incoming: Call) {
    this.pauseAll();
    incoming.sessionContainer.session.accept();
    incoming.accepted = true;
    this._activeCall = incoming;
    this.activeCallChange.emit(incoming);
    if (!location.pathname.includes('call-view')) {
      this.goToCallView();
    }
    this.incomingCallService.removeNotificationByCall(incoming);
    if(this.configService.config.settings?.externalPopup?.openingMode === ExternalPopupOpeningModes.open_on_answer) {
      this.utilService.openExternalLink(this.utilService.replaceExternalPopupKeys(
        this.configService.config.settings.externalPopup.externalLink,
        incoming.number
      ));
    }
  }

  public hangup(call: Call, options?: EndCallOptions) {
    if (!call) {
      this.logger.error('No call to hangup');
      return;
    }
    this.incomingCallService.removeNotificationByCall(call);
    const index = this._calls.findIndex(c => c.id === call.id);
    call.hangup(options);
  }

  public hangupFromRemote(callId: string, options?: EndCallOptions) {
    const id = Number(callId);
    const index = this._calls.findIndex(c => c.id === id);
    if (index === -1) {
      this.logger.error('No call to hangup');
      return;
    }
    const call = this._calls[index];
    call.hangup(options);
  }

  public callClosed(sessionId: string) {
    const index = this._calls.findIndex(c => c.sessionContainer.session.sessionId === sessionId);
    if (index === -1) return;
    this.incomingCallService.removeNotificationByCall(this._calls[index]);
    setTimeout(() => {
      this._calls.splice(index, 1);
      if (this._calls.length) {
        this._activeCall = this._calls[0];
        this.activeCallChange.emit(this._activeCall);
      } else {
        this._activeCall = null;
        this.goToHome();
      }
      this.callsChange.emit(this._calls);
    }, 2000)
  }

  public pauseCall(call: Call) {
    if (call.isPaused) {
      this.pauseAll();
    }
    call.togglePause();
  }

  public goToCallView() {
    this.router.navigateByUrl('/home/call/call-view');
  }

  private searchContactInDbAndCrm(number: string) {
    const searchInDb$ = this.dbService.getFirstContactFromInternal(number);
    const searchInCrm$ = this.addressbookService.searchInCrmsByPhone({
      phone: number
    });
    return searchInDb$.pipe(
      mergeMap(res => iif(() => Boolean(res), of(res), searchInCrm$))
    );
  }


  public goToHome() {
    if(this.router.url.includes('call-view')) {
      this.navigationService.back();
    }
  }

  transfer(target: Call, initiator: Call) {
    initiator.transfer(target.sessionContainer.session);
  }

  private pauseAll() {
    this._calls.forEach(c => {
      if (c.sessionContainer.type === SESSION_TYPE.ANSWERED)
        c.setPause();
    })
  }

  private attachToRemoteActions() {
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.on(IPC_CHANNELS.REJECT_INCOMING_CALL, (event: Electron.IpcRendererEvent, ...args: any[]) => {
        const call = this._calls.find(c => c.id === args[0]);
        this.hangup(call, {
          rejectCode: 486
        });
        this.incomingCallService.removeNotificationByCall(call);
      });

      this.electronService.ipcRenderer.on(IPC_CHANNELS.ACCEPT_INCOMING_CALL, (event: Electron.IpcRendererEvent, ...args: any[]) => {
        const call = this._calls.find(c => c.id === args[0]);
        this.acceptCall(call);
        this.incomingCallService.removeNotificationByCall(call);
      });
    }
  }
}