import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsFacade } from '../../store/facade';
import { Logger, LoggerService } from '../logger';
import { ConfigService } from '../config';


// TODO: Add other audio type (dtml, occupied, etc)
export enum AUDIO_TYPE {
  CONNECTION = 'connection',
  OUTGOING_RING = 'outgoing', // Ringing when doing outgoing call
  INCOMING_RING = 'incoming', // Ringing when receiving an incoming call
  WAITING_RING = 'waiting', // Ringing when receiving an incoming call but it's on call wait
  HANGUP_TONE = 'hangup',
  REMOTE = 'remote',
  HUNGUP = 'hungup',
  DTMF_0 = 'dtmf_0',
  DTMF_1 = 'dtmf_1',
  DTMF_2 = 'dtmf_2',
  DTMF_3 = 'dtmf_3',
  DTMF_4 = 'dtmf_4',
  DTMF_5 = 'dtmf_5',
  DTMF_6 = 'dtmf_6',
  DTMF_7 = 'dtmf_7',
  DTMF_8 = 'dtmf_8',
  DTMF_9 = 'dtmf_9',
  DTMF_POUND = 'dtmf_pound',
  DTMF_STAR = 'dtmf_star',
  DTMF = 'dtmf',
}

export const MAX_VOLUME = 255;
export const MIN_VOLUME = 0;

@Injectable({ providedIn: 'root' })
export class AudioService implements OnDestroy{

  private logger: Logger;
  private subscriptions: Subscription[] = [];

  private connectionAudio: HTMLAudioElement;
  private incomingRingAudio: HTMLAudioElement;
  private outgoingRingAudio: HTMLAudioElement;
  private waitingRingAudio: HTMLAudioElement;
  private hangupTone: HTMLAudioElement;
  private remoteAudio: HTMLAudioElement;
  private dtmf_0: HTMLAudioElement;
  private dtmf_1: HTMLAudioElement;
  private dtmf_2: HTMLAudioElement;
  private dtmf_3: HTMLAudioElement;
  private dtmf_4: HTMLAudioElement;
  private dtmf_5: HTMLAudioElement;
  private dtmf_6: HTMLAudioElement;
  private dtmf_7: HTMLAudioElement;
  private dtmf_8: HTMLAudioElement;
  private dtmf_9: HTMLAudioElement;
  private dtmf_pound: HTMLAudioElement;
  private dtmf_star: HTMLAudioElement;
  private dtmf: HTMLAudioElement;

  private speakerAudioList = [];
  private headphoneAudioList = [];

  private ringVolume: number = 0.2;
  private outputVolume: number = 0.2;

  constructor(
    private loggerService: LoggerService,
    private configService: ConfigService
  ) {
    this.logger = this.loggerService.getLoggerInstance('AudioService');

    window['audioService'] = this;

    // As a service has no lifecycle, it will be initialized here
    this.connectionAudio = new Audio('assets/sounds/connection.ogg');
    this.outgoingRingAudio = new Audio('assets/sounds/outbound_ring.ogg');
    this.incomingRingAudio = new Audio('assets/sounds/externalcall.ogg');
    this.waitingRingAudio = new Audio('assets/sounds/waiting.ogg');
    this.hangupTone = new Audio('assets/sounds/waiting.ogg');
    this.dtmf = new Audio('assets/sounds/dtmf.ogg');
    this.dtmf_0 = new Audio('assets/sounds/dtmf_0.ogg');
    this.dtmf_1 = new Audio('assets/sounds/dtmf_1.ogg');
    this.dtmf_2 = new Audio('assets/sounds/dtmf_2.ogg');
    this.dtmf_3 = new Audio('assets/sounds/dtmf_3.ogg');
    this.dtmf_4 = new Audio('assets/sounds/dtmf_4.ogg');
    this.dtmf_5 = new Audio('assets/sounds/dtmf_5.ogg');
    this.dtmf_6 = new Audio('assets/sounds/dtmf_6.ogg');
    this.dtmf_7 = new Audio('assets/sounds/dtmf_7.ogg');
    this.dtmf_8 = new Audio('assets/sounds/dtmf_8.ogg');
    this.dtmf_9 = new Audio('assets/sounds/dtmf_9.ogg');
    this.dtmf_pound = new Audio('assets/sounds/dtmf_pound.ogg');
    this.dtmf_star = new Audio('assets/sounds/dtmf_star.ogg');
    this.remoteAudio = new Audio();

    this.speakerAudioList = [
      this.incomingRingAudio,
    ];

    this.headphoneAudioList = [
      this.remoteAudio,
      this.outgoingRingAudio,
      this.waitingRingAudio,
      this.hangupTone,
      this.dtmf,
      this.dtmf_0,
      this.dtmf_1,
      this.dtmf_2,
      this.dtmf_3,
      this.dtmf_4,
      this.dtmf_5,
      this.dtmf_6,
      this.dtmf_7,
      this.dtmf_8,
      this.dtmf_9,
      this.dtmf_pound,
      this.dtmf_star,
    ];

    const audioConfig = configService.config?.settings?.audio || {};
    this.setOutputVolume(audioConfig.audio_output_volume || 127);
    this.setRingVolume(audioConfig.audio_ring_volume || 127);
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(s => s?.unsubscribe());
  }

  /**
   * Play the selected audio
   * @param {AUDIO_TYPE} audio Audio to play
   * @param {boolean} loop Play the audio in loop or not
   */
  public play(audio: AUDIO_TYPE, loop: boolean = true) {
    if (audio === AUDIO_TYPE.REMOTE) return; //Remote is managed outside
    let audioElement = this.getAudio(audio);
    if(audioElement.currentTime > 0) {
      return;
    }
    audioElement.muted = false;
    // For dtmf loop should be false so it won't repeat
    audioElement.loop = loop;
    audioElement.play();
  }

  /**
   * Pause the selected audio and reset it to start
   * @param {AUDIO_TYPE} audio Audio to play
   */
  public stop(audio: AUDIO_TYPE) {
    if (audio === AUDIO_TYPE.REMOTE) return; //Remote is managed outside
    let audioElement = this.getAudio(audio);
    audioElement.pause();
    audioElement.currentTime = 0;
  }

  public playAndStop(audio: AUDIO_TYPE){
    if(audio === AUDIO_TYPE.REMOTE) return;
    let audioElement = this.getAudio(audio);
    audioElement.onended = () => {
      audioElement.currentTime = 0;
      audioElement.onended = null;
    }
    audioElement.play();
  }

  /**
   * Get the selected audio
   * @param {AUDIO_TYPE} audio Audio to play
   */
  public getAudio(audio: AUDIO_TYPE): HTMLAudioElement {
    switch (audio) {
      case AUDIO_TYPE.CONNECTION:
        return this.connectionAudio;
      case AUDIO_TYPE.OUTGOING_RING:
        return this.outgoingRingAudio;
      case AUDIO_TYPE.INCOMING_RING:
        return this.incomingRingAudio;
      case AUDIO_TYPE.WAITING_RING:
        return this.waitingRingAudio;
      case AUDIO_TYPE.HANGUP_TONE:
        return this.hangupTone;
      case AUDIO_TYPE.REMOTE:
        return this.remoteAudio;
      case AUDIO_TYPE.DTMF_0:
        return this.dtmf_0;
      case AUDIO_TYPE.DTMF_1:
        return this.dtmf_1;
      case AUDIO_TYPE.DTMF_2:
        return this.dtmf_2;
      case AUDIO_TYPE.DTMF_3:
        return this.dtmf_3;
      case AUDIO_TYPE.DTMF_4:
        return this.dtmf_4;
      case AUDIO_TYPE.DTMF_5:
        return this.dtmf_5;
      case AUDIO_TYPE.DTMF_6:
        return this.dtmf_6;
      case AUDIO_TYPE.DTMF_7:
        return this.dtmf_7;
      case AUDIO_TYPE.DTMF_8:
        return this.dtmf_8;
      case AUDIO_TYPE.DTMF_9:
        return this.dtmf_9;
      case AUDIO_TYPE.DTMF_POUND:
        return this.dtmf_pound;
      case AUDIO_TYPE.DTMF_STAR:
        return this.dtmf_star;
      case AUDIO_TYPE.DTMF:
        return this.dtmf;
      default:
        return null;
    }
  }

  /**
   * Method to quickly stop all ringing audio
   * Incoming, Outgoing and waiting.
   */
  public stopAllRinging() {
    this.stop(AUDIO_TYPE.INCOMING_RING);
    this.stop(AUDIO_TYPE.OUTGOING_RING);
    this.stop(AUDIO_TYPE.WAITING_RING);
  }

  /**
   * Set the volume to use in the app
   * @param {number} volume Value for volume between 0 and 255
   */
  public setOutputVolume(volume: number) {
    if (volume < MIN_VOLUME) volume = MIN_VOLUME;
    if (volume > MAX_VOLUME) volume = MAX_VOLUME;
    // Normalize volume in range 0 - 1
    this.outputVolume = volume / MAX_VOLUME;
    
    this.headphoneAudioList.forEach((audio: HTMLAudioElement) => {
      audio.volume = this.outputVolume;
    });
  }

  public setRingVolume(volume: number) {
    if (volume < MIN_VOLUME) volume = MIN_VOLUME;
    if (volume > MAX_VOLUME) volume = MAX_VOLUME;
    // Normalize volume in range 0 - 1
    this.ringVolume = volume / MAX_VOLUME;

    this.speakerAudioList.forEach((audio: HTMLAudioElement) => {
      audio.volume = this.ringVolume;
    });
  }

  public setOutputAudio(audioDevice: MediaDeviceInfo) {
    if(!audioDevice) return;
    const audios = [this.remoteAudio, this.outgoingRingAudio, this.waitingRingAudio];
    for(let i = 0; i < audios.length; i++) {
      const audio = audios[i];
      if((<any>audio).setSinkId) {
        (<any>audio).setSinkId(audioDevice.deviceId).then(() => {
          this.logger.verbose('Output set correctly');
        });
      } else {
        this.logger.warn('This platform has no capability of changing the output device');
        break;
      }
    }
  }

  public setRingAudio(audioDevice: MediaDeviceInfo) {
    if(!audioDevice) return;
    if((<any>this.incomingRingAudio).setSinkId) {
      (<any>this.incomingRingAudio).setSinkId(audioDevice.deviceId).then(() => {
        this.logger.verbose('Incoming ring set correctly');
      });
    } else {
      this.logger.warn('This platform has no capability of changing the output device');
    }
  }
}