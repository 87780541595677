import { Component, Input, OnInit } from '@angular/core';
import { PRESENCE_STATUS, SIP_STATUS } from '../../../core/services';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  public _shortName: string;
  public _statusClass: string = 'none';
  public _image: string = null;
  public _backgroundColor: string = '#000000';

  @Input() set shortName(v: string) {
    this._shortName = v.length > 2 ? v.substring(0, 2).toUpperCase() : v.toUpperCase();
  }

  @Input() shape: string = 'circle';

  @Input() set backgroundColor(v: string) {
    if(typeof v === 'string' && v.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)){
      this._backgroundColor = v;
    } else {
      this._backgroundColor = '#000000'
    }
  }


  @Input() set status(s: PRESENCE_STATUS | SIP_STATUS) {
    switch (s) {
      case SIP_STATUS.ONLINE:
      case SIP_STATUS.OFFLINE:
      case SIP_STATUS.DND:
      case PRESENCE_STATUS.ONLINE:
      case PRESENCE_STATUS.OFFLINE:
      case PRESENCE_STATUS.DND:
      case PRESENCE_STATUS.AVAILABLE:
      case PRESENCE_STATUS.RINGING:
        this._statusClass = s;
        break;
      case PRESENCE_STATUS.ON_THE_PHONE:
        this._statusClass = 'on-the-phone';
        break;
      case PRESENCE_STATUS.NONE:
        this._statusClass = 'd-none';
        break;
      default:
        this._statusClass = 'd-none';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }
}
